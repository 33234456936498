<template>
    <Wrap>
        <h3>Калькулятор</h3>
        <ul class="tabs">
            <li
                v-for="(title, key) in TABS_TEXTS"
                :key="key"
                :class="tab === key ? 'active_tab' : null"
                @click="tab = key"
            >
                {{ title }}
            </li>
        </ul>
        <div>
            <ModuleCovers v-show="tab === TABS.MODULE"/>
            <ChangableCovers v-show="tab === TABS.CHANGABLE"/>
        </div>
    </Wrap>
</template>

<script>
    import {TABS, TABS_TEXTS} from "@/constants/tabs";
    import ModuleCovers from "@/containers/ModuleCoversContainer";
    import ChangableCovers from "@/containers/ChangableCovers";
    import Wrap from "@/components/Wrap";

    export default {
        name: 'App',
        data() {
            return {
                TABS,
                TABS_TEXTS,
                tab: TABS.MODULE,
            };
        },
        components: {
            Wrap,
            ChangableCovers,
            ModuleCovers
        }
    }
</script>
