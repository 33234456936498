<template>
    <div>
        <div class="sizes">
            <span>Длина, см</span>
            <input :value="height" type="text" name="mc-width" readonly/>
        </div>
        <span class="cross">x</span>
        <div class="sizes">
            <span>Ширина, см</span>
            <input :value="width" type="text" name="mc-height" readonly/>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
    export default {
        name: "SizeInputs",
        props: {
            width: Number,
            height: Number
        }
    }
</script>

<style scoped>
    .cross {
        float: left;
        margin-top: 22px;
    }
</style>
