<template>
    <ul class="direction -direction">
        <li
            v-for="(item, key) in items"
            :key="key"
            :data-border="item.toUpperCase()"
            :class="{[`direction-${item}`]: true, active_direction: value === item}"
            @click="$emit('change', item)"
        ><b /></li>
    </ul>
</template>

<script>
    export default {
        name: "DirectionSelector",
        props: {
            value: String
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        data() {
            return {
                items: ['right', 'up']
            }
        }
    };
</script>

<style scoped>

</style>
