<template>
    <div class="pop_container pop_cnt_module" id="module-cover">
        <CoverTypeSelector v-model="type"/>
        <div>
            <h5>Конфигурация площадки</h5>
            <div class="pop_middle">
                <AreaConfig :step="COVERTYPES_TO_STEPS[type]" @refresh="areaRefresh"/>
            </div>
            <div class="pop_right">
                <SizeInputs :width="width" :height="height" />

                <h5>Алюминиевый кант:</h5>
                <KantSelector v-model="kant"/>
                <div class="clear"></div>

                <h5>Направление движения основного потока:</h5>
                <DirectionSelector v-model="direction"/>
            </div>
        </div>
        <PriceList :price-lines="priceLines" :total-price="totalPrice" />
    </div>
</template>

<script>
    import AreaConfig from "@/components/AreaConfig";
    import KantSelector from "@/components/KantSelector";
    import DirectionSelector from "@/components/DirectionSelector";
    import {Position} from "@/services/position";
    import CoverTypeSelector from "@/components/CoverTypeSelector";
    import {COVERTYPES, COVERTYPES_TO_STEPS} from "@/constants/covers";
    import PriceList from "@/components/PriceList";
    import SizeInputs from "@/components/SizeInputs";
    import {convertCantToBorders} from "@/services/utils";

    export default {
        name: "ModuleCoversContainer",
        components: {PriceList, CoverTypeSelector, KantSelector, AreaConfig, DirectionSelector, SizeInputs},
        data() {
            return {
                width: null,
                height: null,
                kant: [],
                direction: 'right',
                type: COVERTYPES.ARABESKA,
                COVERTYPES_TO_STEPS,
                priceLines: [],
                totalPrice: 0
            }
        },
        methods: {
            areaRefresh({width, height}) {
                this.width = width;
                this.height = height;
                this.calculate();
            },
            calculate() {
                const pos = new Position(this.type);
                pos.setDimension({width: this.width, height: this.height});
                pos.setBorder(convertCantToBorders(this.kant));
                pos.setDirection(this.direction);

                const lines = [{
                    title: 'Стоимость покрытия',
                    code: 'quantPrice',
                    cost: 0
                }, {
                    title: 'Кант',
                    code: 'borderPrice',
                    cost: 0
                }];

                var posPrice = pos.getPrice();
                for (var j = 0; j < lines.length; j++) {
                    lines[j].cost += posPrice[lines[j].code];
                }

                this.priceLines = lines;

                this.totalPrice = lines.reduce((prev, curr) => prev + curr.cost, 0);
            }
        },
        watch: {
            kant: 'calculate',
            type: 'calculate',
            direction: 'calculate',
        }
    }
</script>

<style scoped>

</style>
