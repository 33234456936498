<template>
    <div class="-cover-type">
        <button
            v-for="(item, key) in items"
            :key="key"
            :class="item.value === value ? 'active_button' : null"
            class="pop_button"
            @click="$emit('change', item.value)"
        >
            {{ item.title }}
        </button>
        <div id="module-size">
            Размер модуля: {{ size }}
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
    import {COVERTYPES} from "@/constants/covers";

    export default {
        name: "CoverTypeSelector",
        props: {
            value: String
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        data() {
            return {
                items: [
                    {title: 'Арабеска', value: COVERTYPES.ARABESKA, size: '14см x 14см'},
                    {title: 'Клин степ', value: COVERTYPES.CLEANSTEP, size: '40см x 40см'},
                ]
            };
        },
        computed: {
            size() {
                return this.items.filter(x => x.value === this.value)[0].size;
            }
        }
    }
</script>
