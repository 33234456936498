<template>
    <div class="pop_bottom clear" style="padding: 15px 0 0 0;">
        <h5>Расчет стоимости (руб.):</h5>
        <div class="text_block" style="float:right; width: 238px;">
            Расчет является приблизительным, для точного определения стоимости необходим вызов замерщика
        </div>
        <ul class="price_list">
            <li v-for="(price, key) in priceLines" :key="key">
                {{ price.title }}
                <span>{{ formatPrice(price.cost) }}</span>
            </li>
            <li class="total_price">Итого <span>{{ formatPrice(totalPrice) }}</span></li>
        </ul>
    </div>
</template>

<script>
    import {formatPrice} from "@/services/utils";

    export default {
        name: "PriceList",
        props: {
            priceLines: Array,
            totalPrice: Number
        },
        methods: {formatPrice}
    }
</script>

