<template>
    <div class="pop_container pop_cnt_df" id="dirt-floor">
        <div class="pop_left">
            <h5>Размер ковра:</h5>
            <SizeSelector v-model="size" />
            <div class="clear"></div>

            <input type="hidden" name="df-quantity" value="1">
            <h5>Количество замен:</h5>
            <select v-model="changesQuantity" name="df-changes-per-week">
                <option
                    v-for="(text, value) in CHANGES_COUNT_TEXTS"
                    :key="value"
                    :value="value"
                >{{ text }}</option>
            </select>

            <div class="add_button next" @click="add">
                <p>+</p>
                Добавить ковер<br>к расчету
            </div>
            <div class="clear"></div>
        </div>
        <div class="pop_right">
            <h5>Расчет стоимости (руб.):</h5>
            <div style="margin: -38px 0 10px 0; overflow:hidden;">
                <span
                    style="display:block; text-align:center; float:right; font-size: 11px; line-height: 12px; color: #666;">Стоимость<br/>одной замены</span>
            </div>
            <PriceListExtended :key="totalPrice" :price-lines="priceList" :total-price="totalPrice" @remove="remove" />
        </div>
    </div>
</template>

<script>
    import {Offer} from "@/services/offer";
    import {Position} from "@/services/position";
    import {CHANGES_COUNT_TEXTS, COVERTYPES} from "@/constants/covers";
    import {formatPrice} from "@/services/utils";
    import PriceListExtended from "@/components/PriceListExtended";
    import SizeSelector from "@/components/SizeSelector";
    import {CHANGABLE_MATRIX} from "../constants/prices";

    export default {
        name: "ChangableCovers",
        components: {PriceListExtended, SizeSelector},
        data() {
            const offer = new Offer();
            console.log(offer);
            return {
                CHANGABLE_MATRIX,
                CHANGES_COUNT_TEXTS,

                size: `${CHANGABLE_MATRIX[0].width}x${CHANGABLE_MATRIX[0].height}`,
                changesQuantity: 1,

                totalPrice: 0,
                offer,
                priceList: []
            };
        },
        methods: {
            formatPrice,
            add() {
                const pos = new Position(COVERTYPES.CHANGABLE);

                const parsedSize = this.size.split('x');
                pos.setDimension({
                    width: parseInt(parsedSize[0]),
                    height: parseInt(parsedSize[1])
                });

                pos.setQty(1); // количество всегда 1
                pos.setChangesQty(parseFloat(this.changesQuantity));

                this.offer.addPosition(pos);
                this.calculate();
            },
            remove(itemIndex) {
                this.offer.deletePosition(itemIndex);
                this.calculate();
            },
            calculate() {
                const positions = this.offer.getPositions();
                this.totalPrice = 0;
                positions.forEach(position => {
                    const changesQty = position.getChangesQty();
                    const cost = position.getPrice(changesQty);
                    this.totalPrice += cost.cost;
                });
                this.priceList = this.offer.getPositions().map(position => {
                    const changesQty = position.getChangesQty();
                    const cost = position.getPrice(changesQty);
                    console.log(cost);
                    return {
                        text: cost.text,
                        changesQty,
                        priceOneChange: formatPrice(cost.costOneChange.toFixed(2)),
                        price: formatPrice(cost.cost.toFixed(2))
                    }
                });
            }
        }
    }
</script>
