<template>
    <div class="set_sizes_holder">
        <div class="set_sizes">
            <div
                ref="field"
                class="current_size"
                :style="{
                    width: `${width * MAX_WIDTH / 300}px`,
                    height: `${height * MAX_HEIGHT / 300}px`
                }"
            ></div>
        </div>
        <!-- sliders -->
        <div ref="heightSlider" id="height-slider"></div>
        <div ref="widthSlider" id="width-slider"></div>
        <!-- /sliders -->
    </div>
</template>

<script>
    import $ from 'jquery';

    export default {
        name: "AreaConfig",
        props: {
            step: Number
        },
        data() {
            return {
                MAX_WIDTH: 0,
                MAX_HEIGHT: 0,
                width: 0,
                height: 0
            }
        },
        methods: {
            refresh() {
                this.$emit('refresh', {width: this.width, height: this.height});
            }
        },
        mounted() {
            this.refresh();
            this.MAX_WIDTH = this.$refs.widthSlider.offsetWidth - 4;
            this.MAX_HEIGHT = this.$refs.heightSlider.offsetHeight - 4;

            const MAX_VALUE = 300

            $('#width-slider').slider({
                orientation: 'horizontal',
                range: 'min',
                max: MAX_VALUE,
                min: 0,
                value: 0,
                step: this.step,
                slide: (evt, ui) => this.width = ui.value,
                stop: () => {
                    this.refresh();
                }
            });

            $('#height-slider').slider({
                orientation: 'vertical',
                range: 'max',
                max: MAX_VALUE,
                min: 0,
                value: MAX_VALUE,
                step: this.step,
                slide: (evt, ui) => this.height = 300 - ui.value,
                stop: () => {
                    this.refresh();
                }
            });
        },
        watch: {
            step(value) {
                $('#width-slider').slider('option', 'step', value);
                $('#height-slider').slider('option', 'step', value);

                this.width = Math.ceil(this.width / value) * value;
                this.height = Math.ceil(this.height / value) * value;
                this.refresh();
            }
        }
    }
</script>

<style scoped>
    .set_sizes_holder {
        margin-bottom: 20px;
    }
</style>
