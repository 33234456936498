<template>
    <ul class="direction -border">
        <li
            v-for="(item, key) in KANT_BORDERS"
            :key="key"
            :data-border="item.toUpperCase()"
            :class="{[`kant-${item}`]: true, active_direction: value.indexOf(item) !== -1}"
            @click="select(item)"
        ></li>
    </ul>
</template>

<script>
    import {KANT_BORDERS} from "@/constants/covers";

    export default {
        name: "KantSelector",
        props: {
            value: {
                type: Array,
                default: () => []
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        data() {
            return {KANT_BORDERS};
        },
        methods: {
            select(item) {
                let newArray;
                if (this.value.indexOf(item) !== -1) {
                    newArray = this.value.filter(x => x !== item);
                }
                else {
                    newArray = [...this.value];
                    newArray.push(item);
                }
                this.$emit('change', newArray);
            }
        }
    };
</script>

<style scoped>

</style>
